
import { Nullable } from '@/types'
import { Component, Vue, Watch } from 'vue-property-decorator'
import breakpoints from '@/plugins/breakpoints'
import { v4 as uuidv4 } from 'uuid'
import { namespace } from 'vuex-class'
const RoomsStore = namespace('RoomsStore')
// import { RoomModel } from '../MyRoomsPage/models/RoomModel'

import EventsService from './EventsService'
import {
  EventType,
  EventInterface,
  CallInterface,
  CallbackInterface,
} from './types'
import Content from '@/layouts/components/Content.vue'
import BaseTitle from '@/components/base/BaseTitle.vue'
import Spinner from '@/components/Spinner.vue'
import SelectButtonGroup from '@/components/forms/SelectButtonGroup.vue'

import VueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'

const EventList = () => import('./components/EventList.vue')
const EventDetails = () => import('./components/EventDetails.vue')

@Component({
  name: 'EventsPage',
  components: {
    Content,
    BaseTitle,
    Spinner,
    SelectButtonGroup,
    EventList,
    EventDetails,
    VueCustomScrollbar,
  },
  metaInfo: {
    title: 'Заявки',
  },
})
export default class extends Vue {
  // @Watch('filter')
  // onFilterChanged(): void {
  //   this.batch = uuidv4()
  //   this.events = []
  //   this.selected = null
  //   this.page = 0
  //   this.isLastPage = false
  //   this.loading = false
  //   this.details = new Map()
  //   this.current = null
  //   this.detailsLoading = false
  // }
  // @RoomsStore.Action
  // private getRooms!: () => Promise<any[]>
  // events: EventInterface[] = []
  // selected: Nullable<EventInterface> = null
  // details: Map<EventInterface, CallInterface | CallbackInterface> = new Map()
  // current: Nullable<CallInterface | CallbackInterface> = null
  // page = 0
  // isLastPage = false
  // batch = uuidv4()
  // loading = false
  // detailsLoading = false
  // scrollbarSettings = {
  //   minScrollbarLength: 60,
  // }
  // breakpoints = breakpoints
  // filterItems = [
  //   { id: 'callback', title: 'Заявки' },
  //   { id: 'mass_booking', title: 'Заявки общие' },
  //   { id: 'call', title: 'Звонки' },
  //   { id: '', title: 'Все события' },
  // ]
  // filter = ''
  // get currentTitle(): string {
  //   if (this.current) {
  //     const { type, status } = this.current
  //     if (type === 'callback') return 'Заявка на бронь'
  //     if (type === 'call')
  //       return status === 'missed' ? 'Неотвеченный звонок' : 'Звонок'
  //     if (type === 'mass_booking') return 'Заявка на бронь'
  //   }
  //   return ''
  // }
  // get detailsTarget(): string {
  //   return breakpoints.width < 992 ? 'event-list' : 'event-details'
  // }
  // created(): void {
  //   this.getRooms()
  //   this.getEvents()
  // }
  // async getEvents(): Promise<void> {
  //   const batch = this.batch
  //   const page: Nullable<number> = this.page > 0 ? this.page + 1 : null
  //   const type: Nullable<EventType> = (this.filter ||
  //     null) as Nullable<EventType>
  //   this.loading = true
  //   const response = await EventsService.getEvents(page, type)
  //   if (batch === this.batch) {
  //     this.events.push(...response.data)
  //     this.page = response.meta.current_page
  //     this.isLastPage = response.meta.last_page <= this.page
  //     this.loading = false
  //   }
  // }
  // async getEventDetails(event: EventInterface): Promise<void> {
  //   const selected = this.selected
  //   this.detailsLoading = true
  //   const response = await EventsService.getEventDetails(event.type, event.id)
  //   this.details.set(event, response)
  //   event.viewed = response.viewed
  //   if (selected === this.selected) {
  //     this.current = response
  //     this.detailsLoading = false
  //   }
  // }
  // onScrollEnd(): void {
  //   if (!this.isLastPage && !this.loading) {
  //     this.getEvents()
  //   }
  // }
  // onSelect(event: EventInterface): void {
  //   this.selected = event
  //   if (this.details.has(event)) {
  //     this.current = this.details.get(event) || null
  //   } else {
  //     this.current = null
  //     this.getEventDetails(event)
  //   }
  // }
}
